import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import ReactECharts from 'echarts-for-react';
import EChartDailyGraph from './EChartDailyGraph';

class GraphLength extends React.Component {
  static select(chats) {
    let charts = {}

    chats.forEach((chat, index) => {
      let dateKey = chat.dateTime.format('YYYY-MM-DD')
      if (!(chat.user in charts)) {
        charts[chat.user] = {}
      }
      if (!(dateKey in charts[chat.user])) {
        charts[chat.user][dateKey] = {
          value: 0,
          date: chat.dateTime
        }
      }
      charts[chat.user][dateKey].value += chat.text.split(" ").filter(word => word.trim().length > 0).length
    })

    return charts
  }

  tooltipFormatter (value) {
    return `${value} слов`
  }

  constructor(props) {
    super(props);
    this.statsRef = React.createRef();
  }

  // same everywhere
  static calcStats(charts) {
    let interestStats = {};
    for (let user in charts) {
      interestStats[user] = 0;
      for (let dateKey in charts[user]) {
        interestStats[user] += charts[user][dateKey].value;
      }
    }

    let sortedUsers = Object.entries(interestStats)
      .sort(([, a], [, b]) => b - a)
      .map(([user]) => user);

    let mostInterestedUser = sortedUsers[0];
    let leastInterestedUser = sortedUsers[1];
    let interestPerc = interestStats[mostInterestedUser] / interestStats[leastInterestedUser]
    interestPerc = (interestPerc - 1) * 100
    interestPerc = interestPerc.toFixed(2)

    return {
      most: mostInterestedUser,
      least: sortedUsers.length > 2 ? 'все остальные' : leastInterestedUser,
      perc: interestPerc
    }
  }

  static title() {
    return "Чьи сообщения длиннее"
  }

  static subTitle() {
    return "Как много слов использует каждый для выражения своих мыслей"
  }

  render() {
    const { chats, chatsFilter, onDateRangeSelected } = this.props;
    let charts = GraphLength.select(chats)
    let stats = GraphLength.calcStats(charts) 

    return (
      <div>
        <EChartDailyGraph 
          options={{
            title: GraphLength.title(),
            subtitle: GraphLength.subTitle(),
            yFormatter: this.tooltipFormatter,
            tooltipFormatter: this.tooltipFormatter
          }}
          charts = {charts}
          onDateRangeSelected = {(dateStart, dateEnd) => {
            onDateRangeSelected(dateStart, dateEnd)
            let filteredDialogs = chatsFilter(chats, dateStart, dateEnd)
            let charts = GraphLength.select(filteredDialogs)
            let statsInDateRange = GraphLength.calcStats(charts)
            if (this.statsRef.current) {
              this.statsRef.current.setStats(statsInDateRange)
            }
          }}
        />
        <GraphLengthTimeStats ref={this.statsRef} stats={stats} />
      </div>
    );
  }
}

class GraphLengthTimeStats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      initialStats: props.stats,
      stats: props.stats
    };
  }

  setStats(stats) {
    this.setState((prevState) => ({
      stats: stats
    }))
  }

  render() {
    return (
      <div style={{ backgroundColor: 'white', padding: 20, color: "#777", textAlign: 'center' }}>
        <div>
          <b>{this.state.initialStats.most}</b>&nbsp;
          на <b>{Math.round(this.state.initialStats.perc)}%</b> более многословен,&nbsp;
          чем <b>{this.state.initialStats.least}</b>
        </div>
        <div>
          А на выбранном промежутке <b>{this.state.stats.most}</b>&nbsp;
          на <b>{Math.round(this.state.stats.perc)}%</b> более многословен,&nbsp;
          чем <b>{this.state.stats.least}</b>
        </div>
      </div>
    )
  }

}

export default GraphLength;