import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import ReactECharts from 'echarts-for-react';
import EChartDailyGraph from './EChartDailyGraph';

class GraphFrequency extends React.Component {
  static select(chats) {
    let charts = {}

    chats.forEach((chat, index) => {
      let dateKey = chat.dateTime.format('YYYY-MM-DD')
      if (!(chat.user in charts)) {
        charts[chat.user] = {}
      }
      if (!(dateKey in charts[chat.user])) {
        charts[chat.user][dateKey] = {
          value: 0,
          date: chat.dateTime
        }
      }
      charts[chat.user][dateKey].value += 1
    })

    return charts
  }

  // same everywhere
  static calcStats(charts) {
    let interestStats = {};
    for (let user in charts) {
      interestStats[user] = 0;
      for (let dateKey in charts[user]) {
        interestStats[user] += charts[user][dateKey].value;
      }
    }

    let sortedUsers = Object.entries(interestStats)
      .sort(([, a], [, b]) => b - a)
      .map(([user]) => user);

    let mostInterestedUser = sortedUsers[0];
    let leastInterestedUser = sortedUsers[1];
    let interestPerc = interestStats[mostInterestedUser] / interestStats[leastInterestedUser]
    interestPerc = (interestPerc - 1) * 100
    interestPerc = interestPerc.toFixed(2)

    return {
      most: mostInterestedUser,
      least: sortedUsers.length > 2 ? 'все остальные' : leastInterestedUser,
      perc: interestPerc
    }
  }

  static title() {
    return "Кто пишет больше"
  }

  static subTitle() {
    return "Узнайте, кто больше любит делиться своими мыслями"
  }

  constructor(props) {
    super(props);
    this.statsRef = React.createRef();
  }

  render() {
    const { chats, chatsFilter, onDateRangeSelected } = this.props;
    let charts = GraphFrequency.select(chats)
    let stats = GraphFrequency.calcStats(charts)  

    return (
      <div>
        <EChartDailyGraph 
          options={{
            title: GraphFrequency.title(),
            subtitle: GraphFrequency.subTitle(),
          }}
          charts = {charts}
          onDateRangeSelected = {(dateStart, dateEnd) => {
            onDateRangeSelected(dateStart, dateEnd)
            let filteredDialogs = chatsFilter(chats, dateStart, dateEnd)
            let charts = GraphFrequency.select(filteredDialogs)
            let statsInDateRange = GraphFrequency.calcStats(charts)
            if (this.statsRef.current) {
              this.statsRef.current.setStats(statsInDateRange)
            }
          }}
        />
        <GraphFrequencyStats ref={this.statsRef} stats={stats} />
      </div>
    );
  }
}

class GraphFrequencyStats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      initialStats: props.stats,
      stats: props.stats
    };
  }

  setStats(stats) {
    this.setState((prevState) => ({
      stats: stats
    }))
  }

  render() {
    return (
      <div style={{ backgroundColor: 'white', padding: 20, color: "#777", textAlign: 'center' }}>
        <div>
          <b>{this.state.initialStats.most}</b> пишет  
          на <b>{Math.round(this.state.initialStats.perc)}%</b> больше сообщений, 
          чем <b>{this.state.initialStats.least}</b>
        </div>
        <div>
          А на выбранном промежутке <b>{this.state.stats.most}</b> пишет 
          на <b>{Math.round(this.state.stats.perc)}%</b> больше сообщений, 
          чем <b>{this.state.stats.least}</b>
        </div>
      </div>
    )
  }

}

export default GraphFrequency;