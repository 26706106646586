import React, { useState, useRef } from "react";
import axios from "axios";
import { Button, Header, Image, Container, Message, Loader, Icon } from "semantic-ui-react";
import heartImage from "../heart.png"; 
import { useLocation } from "react-router-dom";

const Uploader = () => {
  const [file, setFile] = useState(null)
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const fileInputRef = useRef(null)
  const location = useLocation()
  const queryArgs = new URLSearchParams(location.search)

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const metaData = {
        app: queryArgs.get("app"),
        os: queryArgs.get("os"), 
        telegramId: queryArgs.get("telegramId"),
        extras: {},
      }
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("data", JSON.stringify(metaData));

      setLoading(true)
      setMessage("")

      try {
        const response = await axios.post("/api/upload-chat", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const trackNumber = response.data.trackNumber;
        const link = `/graph/involvement/${trackNumber}`;
        setMessage(
          <span>
            Анализ отношений - <a href={link}>посмотреть</a>.
          </span>
        );
      } catch (error) {
        console.error("Error uploading file:", error);
        setMessage("Failed to upload the file.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Container textAlign="center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Image src={heartImage} alt="Upload icon" size="small" centered />

      <Header as="h2">Проверь свои отношения</Header>

      <Button 
        color="violet"
        size="big"
        onClick={handleUploadClick}
      >
        <Icon name="upload" style={{ marginRight: '8px' }} />
        Загрузить чат
      </Button>

      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />

      <div style={{ marginTop: '1em' }}>
        {loading ? (
          <Loader active inline="centered" />
        ) : (
          message && <Message success>{message}</Message>
        )}
      </div>

      <p style={{ color: "gray", marginTop: "1em" }}>
        Загрузи переписку и узнай, насколько сильно вы заинтересованы друг в друге!
      </p>
    </Container>
  );
};

export default Uploader;
