import React, { useParams } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Segment, Button, Icon, Header } from 'semantic-ui-react';

const TitleWithButtonBack = ({title, subtitle, 
  backgroundColor = "#FFF", 
  titleColor = "#000", 
  subtitleColor = "#444" 
}) => {

  const navigator = useNavigate()
  const handleBack = () => {
    navigator(-1)
  };

  return (
    <Segment basic style={{ backgroundColor: backgroundColor, margin: 0 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button icon="arrow left" color="violet" onClick={handleBack} />
        <div style={{ marginLeft: '1em' }}>
          <Header as="h3" style={{ margin: 0, color: titleColor  }}>
            {title}
            <Header.Subheader style={{ margin: 0, color: subtitleColor }}>{subtitle}</Header.Subheader>
          </Header>
        </div>
      </div>
    </Segment>
  )
};

export default TitleWithButtonBack;