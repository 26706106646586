import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Segment, Loader, Message, Table, Label, Button } from 'semantic-ui-react';

function ChatList() {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleDownload = async () => {
    axios.get('/api/get-chats')
      .then((response) => {
        const data = response.data
        for (let i in data) {
          data[i].time = moment.unix(data[i].utcTs).format("DD MMM YYYY HH:mm:ss")
          delete data[i].utcTs
        }
        const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' })
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = 'uploads.json'
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(url);
        document.body.removeChild(link)
      })
      .catch((error) => {
        alert("Возникла ошибка")
      });
  };

  useEffect(() => {
    axios.get(`/api/get-chats`)
      .then((response) => {
        setChats(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching data');
        setLoading(false);
      });
  }, []);

  if (loading) return <Loader active inline="centered">Loading...</Loader>;
  if (error) return <Message error content={error} />;

  return (
    <Segment>
      <Button primary onClick={handleDownload} icon="download" labelPosition="right" content="Скачать JSON" />
      <Table celled striped textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Time</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>
            <Table.HeaderCell>OS</Table.HeaderCell>
            <Table.HeaderCell>№</Table.HeaderCell>
            <Table.HeaderCell>Telegram ID</Table.HeaderCell>
            <Table.HeaderCell>Графики</Table.HeaderCell>
            <Table.HeaderCell>Статус</Table.HeaderCell>
            <Table.HeaderCell>Число сообщений</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {chats.slice().reverse().map((item, index) => (
            <Table.Row key={index}>
              <Table.Cell>{ moment.unix(item.utcTs).format("DD MMM YYYY HH:mm:ss") }</Table.Cell>
              <Table.Cell>{item.app}</Table.Cell>
              <Table.Cell>{item.os}</Table.Cell>
              <Table.Cell>{item.trackNumber}</Table.Cell>
              <Table.Cell>{item.telegramId}</Table.Cell>
              <Table.Cell>
                {item.processResult === "OK" && (
                  <div>
                    <a href={`/graph/frequency/${item.trackNumber}`}>Frequency</a>,&nbsp;
                    <a href={`/graph/response/${item.trackNumber}`}>Response</a>,&nbsp;
                    <a href={`/graph/length/${item.trackNumber}`}>Length</a>,&nbsp; 
                    <a href={`/graph/involvement/${item.trackNumber}`}>Involvement</a>
                  </div>
                )}
              </Table.Cell>
              <Table.Cell>
                <Label color={item.processResult === "OK" ? 'green' : 'red'}>
                  {item.processResult}
                </Label>
              </Table.Cell>
              <Table.Cell>{item.itemsCount}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
}

export default ChatList;