import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import ReactECharts from 'echarts-for-react';
import EChartDailyGraph from './EChartDailyGraph';
import GraphFrequency from './GraphFrequency';
import GraphResponseTime from './GraphResponseTime';
import GraphLength from './GraphLength';

class GraphInvolvement extends React.Component {
  static select(chats) {
    const calcFactorSum = (chart, dataOut) => {
    Object.keys(chart).forEach((user) => {
      Object.keys(chart[user]).forEach((dateKey) => {
        if (!(dateKey in dataOut)) {
          dataOut[dateKey] = 0
        }
        dataOut[dateKey] += chart[user][dateKey].value
      });
    });
  }

  let frequencyChart = GraphFrequency.select(chats)
  let frequencyChartTotal = {}
  calcFactorSum(frequencyChart, frequencyChartTotal)

  let responseChart = GraphResponseTime.select(chats)
  let responseChartTotal = {}
  calcFactorSum(responseChart, responseChartTotal)

  let lengthChart = GraphLength.select(chats)
  let lengthChartTotal = {}
  calcFactorSum(lengthChart, lengthChartTotal)

  let charts = {}
  Object.keys(frequencyChart).forEach((user) => {
    charts[user] = {}
    Object.keys(frequencyChart[user]).forEach((dateKey) => {
      let entry = frequencyChart[user][dateKey]
      // the bigger is factor, the more user is interested(!)
      let frequencyFactor = dateKey in frequencyChartTotal && dateKey in frequencyChart[user] && frequencyChartTotal[dateKey] > 0 ? 
        frequencyChart[user][dateKey].value / frequencyChartTotal[dateKey] :
        0
      let lengthFactor = dateKey in lengthChartTotal && dateKey in lengthChart[user] && lengthChartTotal[dateKey] > 0? 
        lengthChart[user][dateKey].value / lengthChartTotal[dateKey] :
        0
      let responseFactor = dateKey in responseChartTotal && dateKey in responseChart[user] && responseChartTotal[dateKey] > 0? 
        // inverse as far as less value here means bigger interest
        1.0 - (responseChart[user][dateKey].value / responseChartTotal[dateKey]) :
        0
      let weightedSum = GraphInvolvement.weightedCoefficient(
        [frequencyFactor, lengthFactor, responseFactor],
        [2, 3, 5]
      )
      charts[user][dateKey] = {
        value: Math.round(weightedSum * 100),
        date: entry.date
      }
    });
  })

  return charts
  }

  static weightedCoefficient(values, weights) {
    let weightedSum = 0;
    let totalWeight = 0;

    values.forEach((value, index) => {
      weightedSum += value * weights[index]; 
      totalWeight += weights[index];         
    });

    return weightedSum / totalWeight; 
  }

  // same everywhere
  static calcStats(charts) {
    let interestStats = {};
    for (let user in charts) {
      interestStats[user] = 0;
      for (let dateKey in charts[user]) {
        interestStats[user] += charts[user][dateKey].value;
      }
    }

    let sortedUsers = Object.entries(interestStats)
      .sort(([, a], [, b]) => b - a)
      .map(([user]) => user);

    let mostInterestedUser = sortedUsers[0];
    let leastInterestedUser = sortedUsers[1];
    let interestPerc = interestStats[mostInterestedUser] / interestStats[leastInterestedUser]
    interestPerc = (interestPerc - 1) * 100
    interestPerc = interestPerc.toFixed(2)

    return {
      most: mostInterestedUser,
      least: sortedUsers.length > 2 ? 'все остальные' : leastInterestedUser,
      perc: interestPerc
    }
  }

  constructor(props) {
    super(props);
    this.statsRef = React.createRef();
  }

  tooltipFormatter (value) {
    return `${value}%`
  }

  render() {
    const { chats, chatsFilter, trackNumber, onDateRangeSelected } = this.props;
    let charts = GraphInvolvement.select(chats)
    let stats = GraphInvolvement.calcStats(charts)  

    return (
      <div>
        <EChartDailyGraph 
          options={{
            title: "Индекс взаимного интереса",
            subtitle: "Показывает, насколько вы заинтересованы друг в друге. Чем выше показатель, тем сильнее ваша взаимная активность в переписке.",
            yFormatter: this.tooltipFormatter,
            tooltipFormatter: this.tooltipFormatter
          }}
          charts = {charts}
          onDateRangeSelected = {(dateStart, dateEnd) => {
            onDateRangeSelected(dateStart, dateEnd)
            let filteredDialogs = chatsFilter(chats, dateStart, dateEnd)
            let charts = GraphInvolvement.select(filteredDialogs)
            let statsInDateRange = GraphInvolvement.calcStats(charts)
            if (this.statsRef.current) {
              this.statsRef.current.setStats(statsInDateRange)
            }
          }}
        />
        <GraphInvolvementStats ref={this.statsRef} trackNumber={trackNumber} stats={stats} />
      </div>
    );
  }
}

class GraphInvolvementStats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      initialStats: props.stats,
      trackNumber: props.trackNumber,
      stats: props.stats
    };
  }

  setStats(stats) {
    this.setState((prevState) => ({
      stats: stats
    }))
  }

  render() {
    return (
      <div style={{ backgroundColor: 'white', padding: 20, color: "#777", textAlign: 'center' }}>
        <div>
          <b>{this.state.initialStats.most}</b> проявляет интерес к общению 
          на <b>{Math.round(this.state.initialStats.perc)}%</b> больше, 
          чем <b>{this.state.initialStats.least}</b>
        </div>
        <div>
          А на выбранном промежутке <b>{this.state.stats.most}</b> проявляет интерес к общению 
          на <b>{Math.round(this.state.stats.perc)}%</b> больше, 
          чем <b>{this.state.stats.least}</b>
        </div>
        <a href={`/graph/overview/${this.state.trackNumber}`}>
          <button className="Button">
            Изучить подробнее
          </button>
        </a>
      </div>
    )
  }

}

export default GraphInvolvement;