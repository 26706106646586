import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import moment from 'moment';
import GraphFrequency from './graph/GraphFrequency';
import GraphResponseTime from './graph/GraphResponseTime';
import GraphInvolvement from './graph/GraphInvolvement';
import GraphOverview from './graph/GraphOverview';
import GraphLength from './graph/GraphLength';
import ChatTable from './ChatTable'
import { Loader, Dimmer } from 'semantic-ui-react';

// universal datetime obtained based on browser time
function getLocalDateTime(chat) {
  if (chat["utcTs"] > 0) {
    return moment.unix(chat["utcTs"])
  }
  return moment({ 
    year: chat["year"], 
    month: chat["month"] - 1, // moment.js: Jan=0..Dec=11
    day: chat["day"],
    hour: chat["hour"],
    minute: chat["minute"],
    second: chat["second"],
  })
}

function convertChats(chats) {
  return chats.map((chat, index) => {
    return {
      id: index,
      user: chat.user,
      text: chat.text,
      dateTime: getLocalDateTime(chat),
    }
  })
}

function binarySearchChats(chats, targetDate, isStart) {
  let left = 0;
  let right = chats.length - 1;
  let index = -1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midDate = moment(chats[mid].dateTime);

    if (midDate.isSameOrAfter(targetDate) && isStart) {
      index = mid;
      right = mid - 1;
    } else if (midDate.isSameOrBefore(targetDate) && !isStart) {
      index = mid;
      left = mid + 1;
    } else if (midDate.isBefore(targetDate)) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return index;
}

function chatsFilter(chats, dateStart, dateEnd) {
  const startIndex = binarySearchChats(chats, dateStart, true);
  const endIndex = binarySearchChats(chats, dateEnd, false);

  if (startIndex === -1 || endIndex === -1) {
    return [];
  }

  return chats.slice(startIndex, endIndex + 1);
}

function Chat() {
  const chatTableRef = useRef(null)
  const [chats, setChats] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const graphName = useParams().graphName
  const trackNumber = useParams().trackNumber

  const postStats = async () => {
    try {
      await axios.get(`/api/stats/${trackNumber}?graph=${graphName}`);
    } catch (error) {
      console.error("Error fetching chat data:", error);
    }
  }
  postStats()

  const fetchChats = async (retryCount = 0) => {
    const RETRY_COUNT = 5
    const RETRY_PAUSE = 2000
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/get-chat/${trackNumber}?graph=${graphName}`);
      
      if (response.status < 200 || response.status >= 300) {
        throw new Error('Non-200 response');
      }

      setChats(convertChats(response.data));
      setLoading(false)
      setError(null)
    } catch (err) {
      if (retryCount < RETRY_COUNT) {
        setTimeout(() => {
          fetchChats(retryCount + 1); 
        }, RETRY_PAUSE); 
      } else {
        setError(`Не удалось отобразить график. Обратитесь в поддержку и сообщите этот номер - ${trackNumber}`);
      }
    } finally {
      if (retryCount >= RETRY_COUNT) {
        setLoading(false)
      }
    }
  };

  useEffect(() => {
    fetchChats()
  }, [trackNumber]);

  const onDateRangeSelected = (dateStart, dateEnd) => {
    if (chatTableRef.current) {
      let filteredDialogs = chatsFilter(chats, dateStart, dateEnd)
      chatTableRef.current.onEvent(filteredDialogs)
    }
  }

  const renderGraph = () => {
    switch (graphName) {
      case 'frequency':
        return <GraphFrequency chats={chats} chatsFilter={chatsFilter} onDateRangeSelected={onDateRangeSelected} />
      case 'response':
        return <GraphResponseTime chats={chats} chatsFilter={chatsFilter} onDateRangeSelected={onDateRangeSelected} />
      case 'length':
        return <GraphLength chats={chats} chatsFilter={chatsFilter} onDateRangeSelected={onDateRangeSelected} />
      case 'involvement':
        return <GraphInvolvement chats={chats} chatsFilter={chatsFilter} trackNumber={trackNumber} onDateRangeSelected={onDateRangeSelected} />
      case 'overview':
        return <GraphOverview trackNumber={trackNumber} chats={chats} />
      default:
        return "Неизвестный график."
    }
  };

   return (
    <div style={{ position: 'relative', minHeight: '100vh' }}> 
      <Dimmer active={loading} inverted style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <Loader size='large'>Загрузка...</Loader> 
      </Dimmer>
      {error && <p>{error}</p>}
      {!loading && !error && (
        <>
          {renderGraph()}
          <ChatTable 
            ref={chatTableRef}
            initialChats={chats} 
          />
        </>
      )}
    </div>
  );
}

export default Chat;