import React, { useState } from 'react';
import Uploader from './Uploader';
import { Container, Card, Icon, Grid, Header, Button, Accordion, List } from 'semantic-ui-react';

function Main() {
  return (
    <Container>
      <Uploader />
      <div>
        <Header as='h2' textAlign='center' style={{ margin: '1rem 0' }}>
          Информация
        </Header>
        <Grid columns={2} stackable centered>
          <Grid.Row>
            <Grid.Column style={{ display: 'flex', justifyContent: 'center' }}>
              <ExpandableTextCard
                title="Whatsapp"
                icon="whatsapp"
                description={waInstruction()}
              />
            </Grid.Column>
            <Grid.Column style={{ display: 'flex', justifyContent: 'center' }}>
              <ExpandableTextCard
                title="Telegram"
                icon="telegram"
                description={tgInstruction()}
              />
            </Grid.Column>        
          </Grid.Row>
        </Grid>
      </div>
      <div style={{ margin: '2rem 0', backgroundColor: "#A394FF", padding: '0', borderRadius: '1rem' }}>
        <Grid columns={2} stackable centered>
          <Grid.Row>
            <Grid.Column style={{ display: 'flex', justifyContent: 'center' }}>
              <Card style={{ margin: '1rem', borderRadius: '1rem', textAlign: 'center' }}>
                <Card.Content>
                  <Icon name="file" size="huge" color="violet" />
                  <Card.Header style={{ marginTop: '10px', fontSize: '0.9rem' }}>
                    Поддерживаются файлы формата .zip, .txt, .json
                  </Card.Header>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column style={{ display: 'flex', justifyContent: 'center' }}>
              <Card style={{ margin: '1rem', borderRadius: '1rem', textAlign: 'center' }}>
                <Card.Content>
                  <Icon name="check" size="huge" color="violet" />
                  <Card.Header style={{ marginTop: '10px', fontSize: '0.9rem' }}>
                    Анонимность гарантирована
                  </Card.Header>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Container>
  );
}

function waInstruction() {
  return (
    <div>
      <ExpandableText 
        title="iOS"
        description={waInstructionIOS()}
      />
      <ExpandableText 
        title="Android"
        description={waInstructionAndroid()}
      />
    </div>
  )
}

function waInstructionIOS(link='https://youtube.com/shorts/n4IPBULJpcE') {
  return (
    <Container style={{ textAlign: 'left' }}>
        <List ordered>
          <List.Item>Откройте чат с контактом, переписку с которым вы хотите проанализировать.</List.Item>
          <List.Item>Нажмите на данные контакта в верхней части экрана, там, где указаны имя и аватарка.</List.Item>
          <List.Item>Прокрутите вниз и выберите опцию "Экспорт чата".</List.Item>
          <List.Item>Выберите формат "Без медиафайлов".</List.Item>
          <List.Item>Сохраните файл в папку "Файлы" на устройстве.</List.Item>
          <List.Item>Загрузите файл для анализа.</List.Item>
        </List>
        <p>
          Для подробной информации смотрите <a href={link} target='_blank'>видеоинструкцию</a>
        </p>
    </Container>
  );
}

function waInstructionAndroid(link='https://youtube.com/shorts/REKupX-PKk0') {
  return (
    <Container style={{ textAlign: 'left' }}>
         <List ordered>
          <List.Item>Откройте чат с нужным контактом в приложении.</List.Item>
          <List.Item>Нажмите на три вертикальные точки в правом верхнем углу экрана.</List.Item>
          <List.Item>В появившемся меню выберите опцию "Ещё".</List.Item>
          <List.Item>Далее нажмите "Экспорт чата".</List.Item>
          <List.Item>Выберите опцию "Без файлов", чтобы сохранить только текст сообщений без вложений.</List.Item>
          <List.Item>Сохраните файл на своё устройство. Если такой возможности нет, отправьте его в другой удобный для вас чат (например, самому себе), а затем скачайте на устройство.</List.Item>
          <List.Item>После того как файл будет на вашем устройстве, загрузите его для анализа.</List.Item>
        </List>
        <p>
          Для подробной информации смотрите <a href={link} target='_blank'>видеоинструкцию</a>
        </p>
    </Container>
  );
}

function tgInstruction() {
  return (
    <div>
      Для экспорта чата, пожалуйста, воспользуйтесь приложением Telegram на компьютере. Это очень просто! 😊
      К сожалению, экспорт чата на мобильных устройствах сейчас недоступен.
      <ExpandableText 
        title="macOS"
        description={tgInstructionMacOS()}
      />
      <ExpandableText 
        title="Windows"
        description={tgInstructionWindows()}
      />
    </div>
  )
}

function tgInstructionMacOS(link='https://youtu.be/uX6gvKlpPW4') {
  return (
    <Container style={{ textAlign: 'left' }}>
        <List ordered>
          <List.Item>
              Перейдите на <a href="https://desktop.telegram.org/" target="_blank" rel="noopener noreferrer">сайт</a> и скачайте Telegram для macOS.
          </List.Item>
          <List.Item>Установите приложение.</List.Item>
          <List.Item>Откройте нужный чат в Telegram в новом приложении.</List.Item>
          <List.Item>Кликните на три точки в верхнем правом углу.</List.Item>
          <List.Item>Выберите "Экспорт истории чата".</List.Item>
          <List.Item>Снимите все галочки в чекбоксах.</List.Item>
          <List.Item>Выберите формат "JSON".</List.Item>
          <List.Item>Увеличьте лимит размера файла, если нужно.</List.Item>
          <List.Item>Укажите папку для сохранения файла.</List.Item>
          <List.Item>Выберите временной интервал переписки для экспорта.</List.Item>
          <List.Item>Разрешите экспорт на другом устройстве, если появится запрос.</List.Item>
          <List.Item>Найдите файл "result.json" в папке "Загрузки" -> "Telegram Desktop" -> "ChatExport_Дата выгрузки", если вами не была выбрана другая папка для сохранения.</List.Item>
          <List.Item>Загрузите файл в бот.</List.Item>
        </List>
        <p>
          Для подробной информации смотрите <a href={link} target='_blank'>видеоинструкцию</a>
        </p>
    </Container>
  );
}

function tgInstructionWindows(link='https://youtu.be/rUA6tq9jMYw') {
  return (
    <Container style={{ textAlign: 'left' }}>
         <List ordered>
            <List.Item>
                Перейдите на <a href="https://desktop.telegram.org/" target="_blank" rel="noopener noreferrer">сайт</a> и скачайте Telegram для Windows.
            </List.Item>
            <List.Item>Установите приложение.</List.Item>
            <List.Item>Откройте нужный чат в Telegram.</List.Item>
            <List.Item>Кликните на три точки в верхнем правом углу.</List.Item>
            <List.Item>Выберите "Экспорт истории чата".</List.Item>
            <List.Item>Снимите все галочки в чекбоксах.</List.Item>
            <List.Item>Выберите формат "JSON".</List.Item>
            <List.Item>Увеличьте лимит размера файла, если нужно.</List.Item>
            <List.Item>Укажите папку для сохранения файла.</List.Item>
            <List.Item>Выберите временной интервал переписки для экспорта.</List.Item>
            <List.Item>Разрешите экспорт на другом устройстве, если появится запрос.</List.Item>
            <List.Item>Найдите файл "result.json" в папке "Загрузки" -> "Telegram Desktop" -> "ChatExport_Дата выгрузки", если вами не была выбрана другая папка для сохранения.</List.Item>
            <List.Item>Загрузите файл в бот.</List.Item>
        </List>
        <p>
          Для подробной информации смотрите <a href={link} target='_blank'>видеоинструкцию</a>
        </p>
    </Container>
  );
}

function ExpandableTextCard({ title, icon, description }) {
  return (
    <Card fluid style={{ borderRadius: '1rem', textAlign: 'center' }}>
      <Card.Content>
        <Icon name={icon} size="huge" color={icon === "telegram" ? "blue" : "green"} />
        <Card.Header style={{ marginTop: '1rem', fontSize: '1.2rem' }}>
          <ExpandableText 
            title={`Инструкция для ${title}`}
            description={description}
          />
        </Card.Header>
      </Card.Content>
    </Card>
  );
}

function ExpandableText({ title, description }) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <Accordion>
      <Accordion.Title 
        active={activeIndex === 0} 
        onClick={() => handleClick(0)}
        style={{ display: 'flex', justifyContent: 'center', padding: 0 }}
      >
        <Button
          color="violet"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            borderRadius: '5px',
            padding: '10px 15px',
            width: '100%',
            fontWeight: 'bold',
            cursor: 'pointer',
            boxShadow: 'none',
          }}
        >
          <Icon name='dropdown' style={{ marginRight: '10px' }} />
          {title}
        </Button>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0} style={{ paddingTop: '10px', textAlign: 'center' }}>
        {description}
      </Accordion.Content>
    </Accordion>
  );
}

export default Main;
