import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import ReactECharts from 'echarts-for-react';
import EChartDailyGraph from './EChartDailyGraph';

class GraphResponseTime extends React.Component {
  static select(chats) {
    let charts = {}
    let waitingChat = null

    chats.forEach((chat, index) => {
      if (waitingChat == null || chat.user != waitingChat.user) {
        if (waitingChat != null) {
          let responseTime = chat.dateTime.diff(waitingChat.dateTime, 'seconds');
          let dateKey = chat.dateTime.format('YYYY-MM-DD')
          if (!(chat.user in charts)) {
            charts[chat.user] = {}
          }
          if (0 <= responseTime && responseTime <= 86400) {
            if (dateKey in charts[chat.user]) {
              charts[chat.user][dateKey].sum += responseTime
              charts[chat.user][dateKey].count += 1
            } else {
              charts[chat.user][dateKey] = {
                sum: responseTime,
                count: 1,
                date: chat.dateTime
              }
            }
          }
        }
        waitingChat = chat
      }
    })

    Object.keys(charts).forEach((user) => {
      Object.keys(charts[user]).forEach((dateKey) => {
        let entry = charts[user][dateKey];
        entry.value = entry.sum / entry.count; 
      })
    })

    return charts
  }

  tooltipFormatter (value) {
    if (value < 60) {
      return `${value}сек.`
    } else if (60 <= value && value < 3600) {
      return `${Math.round(value / 60)}мин.`
    } else {
      return `${Math.round(value / 3600)}ч.`
    }
  }

  constructor(props) {
    super(props);
    this.statsRef = React.createRef();
  }

  // same everywhere
  static calcStats(charts) {
    let interestStats = {};
    for (let user in charts) {
      interestStats[user] = 0;
      for (let dateKey in charts[user]) {
        interestStats[user] += charts[user][dateKey].value;
      }
    }

    let sortedUsers = Object.entries(interestStats)
      .sort(([, a], [, b]) => b - a)
      .map(([user]) => user);

    let mostInterestedUser = sortedUsers[0];
    let leastInterestedUser = sortedUsers[1];
    let interestPerc = interestStats[mostInterestedUser] / interestStats[leastInterestedUser]
    interestPerc = (interestPerc - 1) * 100
    interestPerc = interestPerc.toFixed(2)

    return {
      most: mostInterestedUser,
      least: sortedUsers.length > 2 ? 'все остальные' : leastInterestedUser,
      perc: interestPerc
    }
  }

  static title() {
    return "Кто быстрее отвечает"
  }

  static subTitle() {
    return "Посмотрите, кто из вас ценит каждое мгновение общения"
  }

  render() {
    const { chats, chatsFilter, onDateRangeSelected } = this.props;
    let charts = GraphResponseTime.select(chats)
    let stats = GraphResponseTime.calcStats(charts) 

    return (
      <div>
        <EChartDailyGraph 
          options={{
            title: GraphResponseTime.title(),
            subtitle: GraphResponseTime.subTitle(),
            yFormatter: this.tooltipFormatter,
            tooltipFormatter: this.tooltipFormatter
          }}
          charts = {charts}
          onDateRangeSelected = {(dateStart, dateEnd) => {
            onDateRangeSelected(dateStart, dateEnd)
            let filteredDialogs = chatsFilter(chats, dateStart, dateEnd)
            let charts = GraphResponseTime.select(filteredDialogs)
            let statsInDateRange = GraphResponseTime.calcStats(charts)
            if (this.statsRef.current) {
              this.statsRef.current.setStats(statsInDateRange)
            }
          }}
        />
        <GraphResponseTimeStats ref={this.statsRef} stats={stats} />
      </div>
    );
  }
}

class GraphResponseTimeStats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      initialStats: props.stats,
      stats: props.stats
    };
  }

  setStats(stats) {
    this.setState((prevState) => ({
      stats: stats
    }))
  }

  render() {
    return (
      <div style={{ backgroundColor: 'white', padding: 20, color: "#777", textAlign: 'center' }}>
        <div>
          <b>{this.state.initialStats.least}</b> отвечает  
          на <b>{Math.round(this.state.initialStats.perc)}%</b> быстрее, 
          чем <b>{this.state.initialStats.most}</b>
        </div>
        <div>
          А на выбранном промежутке <b>{this.state.stats.least}</b> отвечает 
          на <b>{Math.round(this.state.stats.perc)}%</b> быстрее, 
          чем <b>{this.state.stats.most}</b>
        </div>
      </div>
    )
  }

}

export default GraphResponseTime;