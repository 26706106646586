import Main from './components/Main'
import Graph from './components/Graph'
import ChatList from './components/ChatList'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Layout from './components/Layout'
import NotFound from './components/NotFound.js'
import ScrollToTop from './components/ScrollToTop'

const App = () => {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Main/>} />
            <Route path="/chats" element={<ChatList/>} />
            <Route path="/graph/:graphName/:trackNumber" element={<Graph />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes> 
      </BrowserRouter>
    )
}

export default App;
