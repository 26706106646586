import React from 'react';
import { Link } from 'react-router-dom';
import GraphInvolvement from './GraphInvolvement';
import GraphFrequency from './GraphFrequency';
import GraphResponseTime from './GraphResponseTime';
import GraphLength from './GraphLength';
import graphImage from './graph.png';
import { Container, Header, Segment, Grid, Image, Divider, Button, Icon, Label } from 'semantic-ui-react';
import TitleWithButtonBack from '../TitleWithButtonBack'
import Gauge from 'react-gauge-component';

class GraphOverview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      chats: props.chats,
      trackNumber: props.trackNumber
    };
  }

  render() {
    let frequencyStats = GraphFrequency.calcStats(GraphFrequency.select(this.state.chats)) 
    let responseStats = GraphResponseTime.calcStats(GraphResponseTime.select(this.state.chats)) 
    let lengthStats = GraphLength.calcStats(GraphLength.select(this.state.chats)) 
    let involvementStats = GraphInvolvement.calcStats(GraphInvolvement.select(this.state.chats)) 

    return (
      <div>
        <TitleWithButtonBack 
          title="Индекс взаимного интереса" 
          titleColor='#A394FF'
        />
        <ChatStatsCard 
          title={GraphFrequency.title()} 
          perc={frequencyStats.perc} 
          msg={(
            <div>
              <u>{frequencyStats.most}</u> пишет больше сообщений, чем <u>{frequencyStats.least}</u> на:
            </div>
          )} 
          link={`/graph/frequency/${this.state.trackNumber}`}
        />
        <Divider style={{ backgroundColor: '#EEE', opacity: '0.5' }} />
        <ChatStatsCard 
          title={GraphResponseTime.title()} 
          perc={responseStats.perc} 
          msg={(
            <div>
              <u>{responseStats.least}</u> отвечает быстрее, чем <u>{responseStats.most}</u> на:
            </div>
          )} 
          link={`/graph/response/${this.state.trackNumber}`}
        />
        <Divider style={{ backgroundColor: '#EEE', opacity: '0.5' }} />
        <ChatStatsCard 
          title={GraphLength.title()} 
          perc={lengthStats.perc} 
          msg={(
            <div>
              <u>{lengthStats.most}</u> пишет больше слов, чем <u>{lengthStats.least}</u> на:
            </div>
          )} 
          link={`/graph/length/${this.state.trackNumber}`}
        />
        <Divider style={{ backgroundColor: '#EEE', opacity: '0.5' }} />
        <ChatStatsCard 
          title="Кто проявляет больше интереса" 
          perc={involvementStats.perc}
          msg={(
            <div>
              <u>{involvementStats.most}</u> больше заинтересован в общении, чем <u>{involvementStats.least}</u> на:
            </div>
          )} 
          link={`/graph/involvement/${this.state.trackNumber}`}
        />
        <Divider style={{ backgroundColor: '#EEE', opacity: '0.5' }} />
      </div>
    )
  }
}

const getScale = () => [
  {
    limit: 20, 
    color: 'LimeGreen',
    tooltip: {
      text: 'Все в порядке'
    }
  },
  {
    limit: 40, 
    color: 'Gold',
    tooltip: {
      text: 'Есть сомнения'
    }
  },
  {
    limit: 60, 
    color: 'Coral',
    tooltip: {
      text: 'Что-то не так'
    }
  },
  {
    limit: 75, 
    color: 'Crimson',
    tooltip: {
      text: 'Серьезные проблемы'
    }
  }
]

const GraphGauge = ({ value }) => {
  return (
    <Gauge
      type="semicircle"
      value={value}
      minValue={0}
      maxValue={Math.max(100, value)}
      arc={{
        subArcs: getScale(),
        width: 0.3,
        padding: 0.003
      }}
      style={{
        width: '80vw',
        maxWidth: '400px',
        height: 'auto'
      }}
      labels={{
        tickLabels: {
          type: "inner",
          hideMinMax: true
        },
        valueLabel: {
          matchColorWithArc: true,
          formatTextValue: value => value.toFixed(0) + '%',
          style: {
            textShadow: null,
            backgroundColor: 'black',
            fontWeight: 'bold',
            fill: 'red'
          }
        }
      }}
      pointer={{
        type: "arrow", 
        color: "#FFF",
        elastic: true,
        animationDelay: 0
      }}
    />
  );
}

const estimateLabel = (value) => {
  let selected = null
  let ranges = getScale()
  for (let i in ranges) {
    selected = ranges[i]
    if (ranges[i].limit >= value) {
      break
    }
  }
  return (
    <Label
      style={{
        backgroundColor: selected.color,
        color: 'inherit',            
        fontSize: '1.5rem',
      }}
    >
      <span style={{color: "#FFF"}}>{selected.tooltip.text}</span>
    </Label>
  )
}

const ChatStatsCard = ({ title, perc, msg, link }) => {
  return (
    <Container textAlign="center" style={{ backgroundColor: '#3F246B', padding: '2rem', borderRadius: '20px' }}>
      <Header as="h1" style={{ color: '#A394FF', fontWeight: 'bold', marginBottom: '1rem' }}>
        {title}
      </Header>
      <Divider style={{ backgroundColor: '#FFFFFF', width: "100%", opacity: '0.1' }} />
      <Segment style={{ backgroundColor: '#3F246B', boxShadow: 'none', border: 'none' }}>
        <Grid columns={2} divided stackable verticalAlign="middle" textAlign="center">
          <Grid.Row>
            <Grid.Column
              width={8}
              style={{ 
                display: 'flex', flexDirection: 'column', alignItems: 'center', 
                justifyContent: 'center', backgroundColor: "#3F246B",
                borderRadius: "2rem", padding: 0
              }}
            >
              <div style={{ fontSize: '1.5rem', color: '#A394FF', padding: "1rem" }}>{msg}</div>
              <div style={{ padding: "1rem" }}>
                <div textAlign="center">
                  <GraphGauge value={perc} />
                </div>
                {estimateLabel(perc)}
              </div>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
              <Image
                src={graphImage}
                style={{ borderRadius: '2rem', width: '100%', height: 'auto', maxWidth: '30vh' }}
                alt="Chat statistics"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <div style={{ marginTop: '2rem' }}>
        <Button as={Link} to={link} fluid color="violet" size="large">
          <Icon name="info circle" /> 
          Изучить подробнее >>
        </Button>
      </div>
    </Container>
  );
};

export default GraphOverview;