import React, { useMemo, useState, forwardRef, useImperativeHandle } from 'react';

const getColorForUser = (user, colors) => {
  if (!colors[user]) {
    const color = `hsl(${Math.random() * 360}, 70%, 80%)`; 
    colors[user] = color;
  }
  return colors[user];
};

const ChatTable = forwardRef(({ initialChats = [] }, ref) => {
  const CHAT_PAGE = 100
  const userColors = useMemo(() => ({}), []);
  const [chats, setChats] = useState(initialChats); 
  const [page, setPage] = useState(1); 
  const [hasMoreChats, setHasMoreChats] = useState(initialChats.length > CHAT_PAGE);

  const loadMoreChats = () => {
    let newPage = page + 1
    setPage(newPage)
    setHasMoreChats(chats.length >= newPage * CHAT_PAGE);
  };

  const onEvent = (filteredChats) => {
    setChats(filteredChats);
    setPage(1)
    setHasMoreChats(filteredChats.length > CHAT_PAGE);
  };

  useImperativeHandle(ref, () => ({
    onEvent, 
  }));

  return (
    <div>
      <h2 style={{ fontSize: '1.2rem', textAlign: 'center' }}>Сообщения ({chats.length})</h2>
      <div>
        {chats.slice(0, page * CHAT_PAGE).map((chat) => (
          <div
            key={chat.id}
            style={{
              padding: '1rem',
              margin: '1px 0',
              backgroundColor: getColorForUser(chat.user, userColors),
            }}
          >
            <div style={{ color: '#555' }}>
              {chat.dateTime.format('DD MMM YYYY HH:mm:ss')}
            </div>
            <div style={{ fontWeight: 'bold' }}>
              <span>{chat.user}</span>
            </div>
            <div style={{ fontSize: '1rem', wordBreak: 'break-word' }}>{chat.text}</div>
          </div>
        ))}
      </div>
      {hasMoreChats && (
        <button
          onClick={loadMoreChats}
          className="Button"
          style={{ width:"100%" }}
          >
          Показать больше сообщений
        </button>
      )}
    </div>
  );
});

export default ChatTable;
