import React from 'react';
import { useInterval } from 'react-use';
import moment from 'moment';
import 'moment/locale/ru';
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import TitleWithButtonBack from '../TitleWithButtonBack'

function createSeries(charts) {

  let allDates = [];
  Object.keys(charts).forEach(user => {
    const userDates = Object.values(charts[user]).map(chart => chart.date);
    allDates = allDates.concat(userDates); // Combine dates into one array
  });
  const minDate = moment.min(allDates).clone().startOf('day');
  const maxDate = moment.max(allDates).clone().endOf('day');

  // fill gaps between days
  /*for (let date = minDate.clone(); date.isSameOrBefore(maxDate); date.add(1, 'days')) {
    let dateKey = date.format('YYYY-MM-DD')
    for (let user in charts) {
      if (!(dateKey in charts[user])) {
        charts[user][dateKey] = {
          value: 0,
          date: date.clone()
        }
      }
    }
  }*/

  let series = []
  let size = 0
  for (let user in charts) {
    const data = Object.values(charts[user])
      .sort((a, b) => a.date.isBefore(b.date) ? -1 : 1)
      .map(item => [item.date.format('YYYY-MM-DD'), item.value])
    size = data.length
    series.push({
        name: user,
        type: 'line',
        data: data,
        smooth: true,
        lineStyle: {
          //color: 'rgb(75, 192, 192)',
        },
        //areaStyle: {},
      })
  }

  return {
    size: size,
    series: series, 
    minDate: minDate, 
    maxDate: maxDate
  }
}

function EChartDailyGraph({options, onDateRangeSelected, charts}) {
  const chart = createSeries(charts)
  let dateRangeStart = null
  let dateRangeEnd = null
  let dateRangeReported = true

  const findCoeffByDate = function (date) {
    const startMoment = chart.minDate.clone();
    const endMoment = chart.maxDate.clone();
    const durationMs = endMoment.diff(startMoment);
    const offsetMs = date.diff(startMoment);
    const coeff = (offsetMs / durationMs) * 100;
    return coeff;
  }

  const calculateZoomOffset = function(chart) {
    const minPoints = 10
    let sinceDate = chart.series[0].data.slice(-minPoints)[0][0]
    return findCoeffByDate(moment(sinceDate))
  }

  const option = {
    animation: true,
    grid: {
      left: '0%', 
      right: '5%',
      containLabel: true, 
    },
    legend: {
      orient: 'horizontal',         
      left: 'center',              
      bottom: 'bottom'
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        let tooltipContent = `${moment(params[0].axisValue).format('D MMM YYYY')}<br/>`;
        params.forEach(item => {
          let value = item.value[1]
          let formatted = (options && options.tooltipFormatter) ? options.tooltipFormatter(value) : value
          tooltipContent += 
            `<div>${item.marker}${item.seriesName}: <b>${formatted}</b></div>`;
        });
        return tooltipContent;
      },
    },
    xAxis: {
      type: 'time',
      axisLabel: {
        rotate: -20,
        formatter: (value) => moment(value).format('D MMM YYYY'), 
      },
      splitLine: {
          show: false,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (options && options.yFormatter) ? options.yFormatter : null
      },
      splitLine: {
          show: true,
          lineStyle: {
            type: [5, 5],
            color: 'white',
            width: 0.5, 
          }
      },
    },
    dataZoom: [
      {
        type: 'inside',
        start: calculateZoomOffset(chart),
        end: 100,
        zoomLock: false
      },
      {
        bottom: '10%',
        start: calculateZoomOffset(chart),
        end: 100,
        zoomLock: false,
        brushSelect: false,
      }
    ],
    series: chart.series,
  };

  const theme = {
    color: ['Yellow', '#6435c9', 'Blue', 'Red', 'Black', 'Green', 'Orange'],
    backgroundColor: '#A394FF',
    textStyle: {
      color: 'white',
    },
    title: {
      textStyle: {
        color: 'white',
      },
      subtextStyle: {
        color: '#EEE',
        fontSize: 14,
        overflow: 'break'
      },
    },
    axisLine: {
      lineStyle: {
        color: 'white',
      },
    },
    legend: {
      textStyle: {
        color: 'white',
      },
    },
    dataZoom: {
      textStyle: {
        color: 'white', 
      },
    },
  };
  echarts.registerTheme('theme', theme);

  const findDateByCoefficient = function (coeff) {
    coeff /= 100
    const startMoment = chart.minDate.clone();
    const endMoment = chart.maxDate.clone();
    const durationMs = endMoment.diff(startMoment);
    const offsetMs = coeff * durationMs;
    const desiredDate = startMoment.add(offsetMs, "milliseconds");
    return desiredDate;
  }

  const onDateRangeChanged = (start, end) => {
    dateRangeStart = findDateByCoefficient(start).subtract(1, 'seconds')
    dateRangeEnd = findDateByCoefficient(end).add(1, 'seconds')
    dateRangeReported = false
  }

  useInterval(() => {
    if (!dateRangeReported) {
      onDateRangeSelected(dateRangeStart, dateRangeEnd)
      dateRangeReported = true
    }
  }, 100); 

  const onChartEvents = {
    datazoom: (params) => {
      if (params.start !== undefined && params.end !== undefined) {
        onDateRangeChanged(params.start, params.end)
      } else {
        onDateRangeChanged(params.batch[0].start, params.batch[0].end)
      }
    }
  };

  const onChartReady = (chart) => {
    const options = chart.getOption()
     if (options.dataZoom && options.dataZoom.length > 0) {
      const dataZoom = options.dataZoom[0]
      const start = dataZoom.start
      const end = dataZoom.end
      onDateRangeChanged(start, end)
    }
  }

  return (
    <div>
      <TitleWithButtonBack 
        backgroundColor='#A394FF'
        titleColor = "#FFF"
        subtitleColor = "#DDD" 
        title={options && options.title && (options.title)} 
        subtitle={options && options.subtitle && (options.subtitle)} 
      />
      <ReactECharts 
        option={option} 
        style={{ height: '400px', width: '100%' }}
        theme='theme'
        onEvents={onChartEvents}
        onChartReady={onChartReady}
      />
    </div>
  );
}

export default EChartDailyGraph;