import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Segment, Button, Icon } from 'semantic-ui-react';

const Layout = () => {
  return (
    <div style={{ margin: 0, padding: 0 }}>
      <Segment basic style={{ padding: 0 }}> 
        <Outlet /> 
      </Segment>
    </div>
  )
};

export default Layout;